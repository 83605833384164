<template>
    <div>
        <base-header class="pb-6">
            <b-row align-v="center" class="py-4">
                <b-col lg="6" cols="7">
                <h6 class="h2 text-white d-inline-block mb-0">Setup</h6>
                <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                    <route-bread-crumb></route-bread-crumb>
                </nav>
                </b-col>

            </b-row>
        </base-header>

        <div class="mt--6 header pb-6 d-flex align-items-center profile-header">
            <!-- Mask -->
            <span class="mask bg-gradient-default opacity-8"></span>
            <!-- Header container -->
            <b-container fluid class="d-flex align-items-center">
                <b-row >
                <b-col lg="7" md="10">
                    <h1 class="display-2 text-white">Hello, {{userInfo()}}</h1>
                    <p class="text-white mt-0 mb-5">This is your profile page. </p>
                </b-col>
                </b-row>
            </b-container>
        </div>

        <b-container fluid class="mt--6">
            <edit-my-profile-form></edit-my-profile-form>
        </b-container>
    </div>
</template>
<script>
import axios from 'axios'
import EditMyProfileForm from '@/views/Setup/UsersFolder/UserProfile/EditMyProfileForm.vue';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import {getUserInfo, apiDomain, getHeader} from '@/assets/js/config.js'

export default {
    name: 'my-user-profile',
    components: {
        EditMyProfileForm,
        RouteBreadCrumb
    },
    data() {
        return {
            user: {        
                name: '',
                email: '',
            }
        }
    },
    methods: {
        userInfo()
        {
            axios.get(apiDomain + 'api/my_profile/' + getUserInfo().id ,{ headers: getHeader() })
                .then ( response => {
                if (response.status === 200)
                {
                    this.user.name      = response.data.data[0].name
                    this.user.email     = response.data.data[0].email
                }
                })
                .catch ( error => {
                    console.log(error.response.status)
                    this.errorFunction(error, "Show User")
                
            });
            return this.user.name;
        }
    }
};
</script>
<style>
    .profile-header {
        background-size: cover;
        background-position: center top;
        min-height: 200px;
    }
</style>
